$primaryFontFamily: "carbona-variable", sans-serif !default;
$primaryColor: #054561 !default;
$primaryDarkColor: #598392 !default;
$primaryDarkerColor: #598392 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #fafafa !default;
$highlightTextColor: #598392 !default;
$green: #ade3bc !default;
$dark-green: #92d5a6;
$red: #fd656c !default;
$pink: #eabed1;
$dark-pink: #e8cad7;
$lightRed: #ffb5b5;
$orange: #feb76a;
$dark-orange: #f7cb95;
$blue: #9fadbf;
$turquoise: #82ccd9;
$dark-turquoise: #91bdcc;
$grey: #e0dfdf;
$dark-grey: #cbcbcb;
$mouseover-blue: #7a94b5;
$mouseover-grey: #bcbcbc;
$leftPanelWidth: 250px;
$beige: #efeee1;
$new-turquoise: #14b0bd;
$new-beige: #ebe0c4;
$new-orange: #ffc28c;

:root {
  --primaryFontFamily: "carbona-variable", sans-serif;
  --leftMenuWidth: 250px;
  --primaryYellow: #efeee1;
  --heightLine: 60px;
  --borderRadius: 0.3rem;
  --negativeMargin: -0.5rem;
  --primaryBlue: #054561;
  --secondaryBlue: #598392;
  --purpleDocument: #9fadbf;
  --blueVariable: #82ccd9;
  --blueSubVariable: #91bdcc;
  --greenCheck: #ade3bc;
  --heightVariable: 24.5px;
  --lightRed: #ffb5b5;
  --lightRedBis: #ff999e;
  --highlightBg: #fafafa;
  --beige: #efeee1;
  --red: #fd656c;
  --darkGreen: #85c999;
  --veryDarkGreen: #92d5a6;
  --primaryTextColor: #ffffff;
  --lightGrey: #f0f0f0;
  --grey: #e0dfdf;
  --new-turquoise: #14b0bd;
  --new-beige: #ebe0c4;
  --new-orange: #ffc28c;
  --dark-grey: #cbcbcb;
  --blue: #9fadbf;

  $primaryColor-OLD: #124559 !default;
}
